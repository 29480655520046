<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="4">
        <v-text-field
          label="Receta"
          background-color="white"
          v-model="recipeCode"
          rounded
          filled
          hide-details
          prepend-inner-icon="mdi-magnify"
          @keyup.enter="filterDevolutions"
        >
        </v-text-field
      ></v-col>
      <v-col cols="4">
        <v-text-field
          label="Farmacia"
          background-color="white"
          v-model="pharmacyCode"
          rounded
          filled
          hide-details
          prepend-inner-icon="mdi-magnify"
          append-outer-icon="mdi-send"
          @click:append-outer="filterDevolutions"
          @keyup.enter="filterDevolutions"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8" offset="2">
        <v-sheet class="pa-10 rounded20">
          <v-row>
            <v-spacer />
            <v-chip color="teal lighten-1" outlined dark label>
              <span class="overline">Devoluciones sin respuesta</span>
            </v-chip>
            <v-spacer />
          </v-row>
          <template v-if="!devolutions.length && !loading">
            <v-row>
              <v-spacer />
              <span class="overline">NO HAY DEVOLUCIONES PENDIENTES</span>
              <v-spacer />
            </v-row>
          </template>
          <v-data-table
            v-if="!loading && devolutions.length"
            :headers="tableHeaders"
            :items="devolutions"
            :expanded.sync="expanded"
            :single-expand="true"
            item-key="id"
            show-expand
            class="elevation-0 mt-5"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pa-0">
                <div>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Id Item</th>
                          <th class="text-left">Troquel</th>
                          <th class="text-left">Cantidad devuelta</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in item.items" :key="item.itemId">
                          <td>{{ item.itemId }}</td>
                          <td>{{ item.troquel }}</td>
                          <td>{{ item.ammount }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </td>
            </template>
            <template v-slot:item.button="{ item }">
              <v-btn
                elevation="0"
                color="teal"
                dark
                rounded
                @click="openDialog(item)"
              >
                Responder devolución
              </v-btn>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="detailOpen" max-width="700px">
      <v-card class="rounded-xl">
        <v-form ref="form">
        <v-card-title>
          Confirmar devolución de la receta "{{ currentItem.recipe }}"
        </v-card-title>
        <v-card-text>
        <v-simple-table>
           <thead >
            <tr>
              <th class="text-center">
              Troquel
              </th>
              <th class="text-center">
                A devolver
              </th>
              <th class="text-center">
                Devuelto
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in currentItem.items"
              :key="index"
              class="text-center"
            >
              <td>{{ item.troquel }}</td>
              <td>{{ item.ammount }}</td>
              <td style="max-width:90px">
                <v-text-field
                  v-model="item.refound"
                  label="Cantidad devuelta"
                  rounded
                  filled
                  class="ma-1"
                  dense
                  type="number"
                  :rules="[
                    v => v >= 0 || 'La cantidad debe ser mayor o igual a 0',
                    v => v <= item.ammount || 'La cantidad debe ser menor o igual a la cantidad devuelta'
                  ]"
                  required
                />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        </v-card-text>
        <v-card-text>
         <v-textarea
              :rules="[(v) => !!v || 'Ingrese un mensaje']"
              ref="message"
              required
              prepend-icon="mdi-message"
              append-outer-icon="mdi-send"
              label="Respuesta a la devolución"
              auto-grow
              filled
              rounded
              v-model="message"
              @click:append-outer="respondDevolution()"
            />
          </v-card-text>
        </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "../../services/eventBus";

export default {
  data: () => ({
    recipeCode: null,
    pharmacyCode: null,
    detailOpen: false,
    message: "",
    currentItem: {},
    expanded: [],
    tableHeaders: [
      {
        text: "#",
        align: "center",
        sortable: false,
        value: "id",
      },
      { text: "Receta", align: "center", value: "recipe" },
      { text: "Farmacia", align: "center", value: "pharmacy" },
      { text: "Fecha", align: "center", value: "date" },
      { text: "Detalle", align: "center", value: "data-table-expand" },
      { text: "Acciones", align: "center", value: "button" },
    ],
    detailProperties: [
      {
        label: "Troquel",
        value: "troquel",
      },
      {
        label: "Cantidad",
        value: "ammount",
      },
    ],
  }),
  computed: {
    ...mapGetters("devolutions", ["devolutions", "loading", "singleLoading"]),
  },
  mounted() {
    this.getDevolutions();
  },
  methods: {
    ...mapActions("devolutions", ["getDevolutions", "saveDevolution"]),

    filterDevolutions() {
      this.getDevolutions({
        recipeCode: this.recipeCode,
        pharmacyCode: this.pharmacyCode,
      });
    },
    respondDevolution() {
      if (this.$refs.form.validate())
        this.saveDevolution({
          item: this.currentItem,
          message: this.message,
        }).then((x) => {
          if (x.status === "Ok") {
            eventBus.$emit("makeAlert", {
              message: `Transacción aprobada`,
              color: "success",
            });
            this.getDevolutions();
            this.closeDialog();

          } else
            eventBus.$emit("makeAlert", {
              message: x.data.message,
              color: "red",
            });
        });
      else
        eventBus.$emit("makeAlert", {
          message: `El formulario es inválido`,
          color: "red",
        });
    },
    openDialog(item) {
      this.detailOpen = true;
      this.currentItem = item;
    },
    closeDialog() {
      this.detailOpen = false;
      this.currentItem = {};
    },
  },
};
</script>
